import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  TextField,
  Link,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { login, otp, settingFetch, settingUpdate } from '../../redux';
import { isObjectEmpty, setupOrg } from 'common/helper';
import { emailSchema } from 'common/schema';
import {
  BETA_DASHBOARD_VERSION,
  CURRENT_DASHBOARD_VERSION,
  LS_STR8LINE_TOKEN,
  ORION_URL,
  SS_STORED_PROJECT_ID
} from 'common/constants';
import LoginAlternatives from '../../components/LoginAlternatives';

const loginSchema = {
  ...emailSchema,
  password: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Password is required"
          id="error.REQUIRED_PASSWORD"
        />
      )
    },
    length: {
      maximum: 128
    }
  }
};

const otpSchema = {
  code: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="One time password is required"
          id="error.REQUIRED_OTP"
        />
      )
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    paddingTop: theme.spacing(7),
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  authLink: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

const SignIn = props => {
  const classes = useStyles();
  const {
    location: { message, search },
    history
  } = props;
  const query = QueryString.parse(search);
  const {
    user: {
      error: userError,
      info: { id, organizations },
      loading: userLoading,
      setting,
      showOtp,
      updated: userUpdated
    }
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    propError: message,
    selectDashboardVersion: false
  });
  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);
  const error = formState.propError || userError;
  const errorMsg = error && typeof error === 'object' ? error.message : error;
  const token = localStorage.getItem(LS_STR8LINE_TOKEN);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (showOtp) dispatch(otp({ ...formState.values, id }));
    else {
      dispatch(login(formState.values));
      setFormState(() => ({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        propError: '',
        selectDashboardVersion: false
      }));
    }
  };

  useEffect(() => {
    const errors = validate(
      formState.values,
      showOtp ? otpSchema : loginSchema
    );

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    if (userUpdated) dispatch(settingFetch(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdated]);

  useEffect(() => {
    if (id && token) {
      if (isObjectEmpty(setting.settings)) dispatch(settingFetch(id));
      else {
        setupOrg(organizations && organizations[0]);

        if (query.project_id) {
          if (query.object_id)
            history.push(
              `/object/${query.object_id}?project_id=${query.project_id}`
            );
          else {
            sessionStorage.setItem(SS_STORED_PROJECT_ID, query.project_id);
            history.push('/objects');
          }
        } else {
          if (!setting.settings.dashboard_version) {
            setFormState(formState => ({
              ...formState,
              selectDashboardVersion: true
            }));
          } else {
            if (
              setting.settings.dashboard_version === CURRENT_DASHBOARD_VERSION
            )
              history.push('/projects');
            else window.location.assign(ORION_URL.concat('/projects'));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, setting, token]);

  const updateUserDashboardSetting = version => {
    // Update user's global configuration
    dispatch(
      settingUpdate({
        ...setting,
        settings: { ...setting.settings, dashboard_version: version }
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Paper className={classes.contentPaper} elevation={4}>
              {errorMsg ? (
                <Alert className={classes.alert} severity="error">
                  <FormattedMessage
                    defaultMessage={
                      <FormattedMessage
                        defaultMessage="Unexpected server response."
                        id="error.UNEXPECTED_RESPONSE"
                      />
                    }
                    id={errorMsg}
                  />
                </Alert>
              ) : showOtp ? (
                <Alert className={classes.alert} severity="info">
                  <FormattedMessage
                    defaultMessage={
                      <FormattedMessage
                        defaultMessage="Please check your email for the one time password."
                        id="main.OTP_INFO_MESSAGE"
                      />
                    }
                    id="main.OTP_INFO_MESSAGE"
                  />
                </Alert>
              ) : null}
              <Typography variant="h2">
                <FormattedMessage defaultMessage="Sign in" id="main.SIGN_IN" />
              </Typography>
              {showOtp ? (
                <>
                  <TextField
                    className={classes.textField}
                    error={hasError('code')}
                    fullWidth
                    helperText={
                      hasError('code') ? formState.errors.code[0] : null
                    }
                    id="code"
                    label={
                      <FormattedMessage
                        defaultMessage="One Time Password"
                        id="common.OTP"
                      />
                    }
                    name="code"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.code || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || userLoading}
                    fullWidth
                    id="verify"
                    size="large"
                    type="submit"
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Verify"
                      id="main.VERIFY_OTP"
                    />
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                    id="email"
                    label={
                      <FormattedMessage
                        defaultMessage="Email address"
                        id="common.EMAIL_ADDRESS"
                      />
                    }
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    id="password"
                    label={
                      <FormattedMessage
                        defaultMessage="Password"
                        id="main.PASSWORD"
                      />
                    }
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || userLoading}
                    fullWidth
                    id="signin"
                    size="large"
                    type="submit"
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Sign in"
                      id="main.SIGN_IN"
                    />
                  </Button>
                  <div className={classes.authLink}>
                    <Typography color="textSecondary" variant="body1">
                      <Link component={RouterLink} to="/forgot" variant="h6">
                        <FormattedMessage
                          defaultMessage="Forgot password?"
                          id="main.FORGOT_PASSWORD"
                        />
                      </Link>
                    </Typography>
                  </div>
                </>
              )}
              <LoginAlternatives />
            </Paper>
          </form>
          <Dialog
            onClose={() =>
              setFormState(fs => ({ ...fs, selectDashboardVersion: false }))
            }
            open={formState.selectDashboardVersion}>
            <DialogContent dividers>
              <Typography gutterBottom>
                {/*  TODO: Update message & create translation */}
                Do you want to try out our Beta site? We will store your
                selection below and you will be redirected to your chosen
                dashboard upon login. If you wish to change your choice, you can
                update it in Admin - User page. You can temporarily switch
                dashboards using the link at the top part of the page.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() =>
                  updateUserDashboardSetting(CURRENT_DASHBOARD_VERSION)
                }
                size="small"
                variant="contained">
                {/*  TODO: Update message & create translation */}
                <FormattedMessage
                  defaultMessage="Stay Here"
                  id="main.STAY_WITH_V1"
                />
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  updateUserDashboardSetting(BETA_DASHBOARD_VERSION)
                }
                size="small"
                variant="contained">
                {/*  TODO: Update message & create translation */}
                <FormattedMessage
                  defaultMessage="Try Beta Site"
                  id="main.TRY_V2_SITE"
                />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(SignIn);
