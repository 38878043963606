import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'common/helper';
import { AGENDA_TITLE, DEFAULT_DATE_FORMAT } from 'common/constants';

export const titleSchema = {
  title: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: <FormattedMessage defaultMessage="Title" id="common.TITLE" />
          }}
        />
      )
    },
    exclusion: {
      within: [
        'NY BHM',
        'NY EO',
        'NY ICO',
        'NY KOE',
        'NY LE',
        'NY REF',
        'NY TA',
        'NY TMPL'
      ],
      message: (
        <FormattedMessage
          defaultMessage="Provide a title for saving"
          id="error.REQUIRED_OBJECT_TITLE"
        />
      )
    },
    length: {
      maximum: 164,
      message: (
        <FormattedMessage
          defaultMessage="{name} is too long (maximum is {num} characters)"
          id="error.MAX_LENGTH"
          values={{
            name: <FormattedMessage defaultMessage="Title" id="common.TITLE" />,
            num: 164
          }}
        />
      )
    }
  }
};

export const recipientSchema = {
  discipline_type_id: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="This field"
                id="common.GENERIC_THIS_FIELD"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentOtherSchema = {
  documentation_type: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Documentation type"
                id="object.DOCUMENTATION_TYPE"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentAttachmentSchema = {
  attachments: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Attachment"
                id="common.ATTACHMENT"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentApproverSchema = {
  discipline_type_id: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Approver"
                id="object.APPROVER"
              />
            )
          }}
        />
      )
    }
  }
};
export const documentViewApproverSchema = {
  organization: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Approver"
                id="object.APPROVER"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentCategorySchema = {
  selected_category: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Category"
                id="common.CATEGORY"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentCategoryNameSchema = {
  name: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Category name"
                id="object.CATEGORY_NAME"
              />
            )
          }}
        />
      )
    }
  }
};

export const documentSchema = {
  title: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          id="error.REQUIRED"
          defaultMessage="{name} is required"
          values={{
            name: <FormattedMessage defaultMessage="Title" id="common.TITLE" />
          }}
        />
      )
    },
    length: {
      maximum: 164,
      message: (
        <FormattedMessage
          defaultMessage="{name} is too long (maximum is {num} characters)"
          id="error.MAX_LENGTH"
          values={{
            name: <FormattedMessage id="common.TITLE" defaultMessage="Title" />,
            num: 164
          }}
        />
      )
    }
  }
};

export const eoSchema = {
  impact: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage defaultMessage="Impact" id="object.IMPACT" />
            )
          }}
        />
      )
    }
  },
  impact_total_schedule_days: {
    numericality: {
      greaterThan: 0,
      onlyInteger: true,
      message: (
        <FormattedMessage
          defaultMessage="{name} must be greater than {value}"
          id="error.MIN_VALUE"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Total schedule impact (days)"
                id="common.TOTAL_SCHEDULE_IMPACT"
              />
            ),
            value: 0
          }}
        />
      )
    }
  }
};

export const eoCommentSchema = {
  new_comment_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Comments"
                id="object.COMMENTS"
              />
            )
          }}
        />
      )
    }
  }
};

export const eoDescriptionSchema = {
  new_description_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            )
          }}
        />
      )
    }
  }
};

export const icoSchema = {
  total_schedule_impact_days: {
    numericality: {
      greaterThan: 0,
      message: (
        <FormattedMessage
          defaultMessage="{name} must be greater than {value}"
          id="error.MIN_VALUE"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Total schedule impact (days)"
                id="common.TOTAL_SCHEDULE_IMPACT"
              />
            ),
            value: 0
          }}
        />
      ),
      onlyInteger: true
    }
  },
  description_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            )
          }}
        />
      )
    }
  }
};

export const koeSchema = {
  reason: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Reason for change"
                id="object.REASON_FOR_CHANGE"
              />
            )
          }}
        />
      )
    }
  },
  impact: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage defaultMessage="Impact" id="object.IMPACT" />
            )
          }}
        />
      )
    }
  },
  impact_total_schedule_days: {
    numericality: {
      greaterThan: 0,
      onlyInteger: true,
      message: (
        <FormattedMessage
          defaultMessage="{name} must be greater than {value}"
          id="error.MIN_VALUE"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Total schedule impact (days)"
                id="common.TOTAL_SCHEDULE_IMPACT"
              />
            ),
            value: 0
          }}
        />
      )
    }
  }
};

export const koeCommentSchema = {
  new_comment_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Comments"
                id="object.COMMENTS"
              />
            )
          }}
        />
      )
    }
  }
};

export const koeDescriptionSchema = {
  new_description_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            )
          }}
        />
      )
    }
  }
};

export const leSchema = {
  content_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage defaultMessage="Message" id="object.MESSAGE" />
            )
          }}
        />
      )
    }
  }
};

export const refSchema = {
  minutes_by: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Minutes by"
                id="object.MINUTES_BY"
              />
            )
          }}
        />
      )
    }
  },
  chaired_by: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Chaired by"
                id="object.CHAIRED_BY"
              />
            )
          }}
        />
      )
    }
  },
  meeting_date: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: <FormattedMessage defaultMessage="Date" id="common.DATE" />
          }}
        />
      )
    }
  }
};

export const refMeetingDateFormatSchema = {
  meeting_date: {
    datetime: {
      dateOnly: true,
      message: (
        <FormattedMessage
          defaultMessage="Invalid date format. Please use yyyy-mm-dd."
          id="error.INVALID_DATE"
        />
      )
    }
  }
};

export const bhmSchema = {
  new_inquiry_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            )
          }}
        />
      )
    }
  }
};

export const taSchema = {
  new_inquiry_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            )
          }}
        />
      )
    }
  },
  new_solutions_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Possible Solutions"
                id="object.POSSIBLE_SOLUTIONS"
              />
            )
          }}
        />
      )
    }
  }
};

export const taDateSchema = {
  due_date: {
    datetime: {
      dateOnly: true,
      message: (
        <FormattedMessage
          defaultMessage="Invalid date format. Please use yyyy-mm-dd."
          id="error.INVALID_DATE"
        />
      )
    }
  }
};

export const taMinDateSchema = {
  due_date_min: {
    datetime: {
      earliest: formatDate(new Date(), DEFAULT_DATE_FORMAT),
      message: (
        <FormattedMessage
          defaultMessage="Due date cannot be prior to issue date."
          id="error.MIN_DUE_DATE"
        />
      )
    }
  }
};

export const taResponseSchema = {
  new_response_text: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Response"
                id="object.RESPONSE"
              />
            )
          }}
        />
      )
    }
  }
};

// special case since fields are dynamic
export const getRefSchema = values => {
  let idx = 0;
  let aSchema = {};
  if (values)
    while (idx < values.length) {
      aSchema[`${AGENDA_TITLE}_${idx}`] = {
        presence: {
          allowEmpty: false,
          message: (
            <FormattedMessage
              defaultMessage="{name} is required"
              id="error.REQUIRED"
              values={{
                name: (
                  <FormattedMessage
                    defaultMessage="Agenda Title"
                    id="object.AGENDA_TITLE"
                  />
                )
              }}
            />
          )
        }
      };
      idx++;
    }
  return aSchema;
};

export const getDelmalebrevSchema = (field, len) => {
  let schema = {};
  for (let idx = 0; idx < len; idx++) {
    schema[`${field}_${idx}`] = {
      presence: { allowEmpty: false }
    };
  }
  return schema;
};
