import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { WysWyg } from '../../components';
import { Clear as ClearIcon, Event as EventIcon } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { formatDate } from 'common/helper';
import { formatForEditor } from '../../components/helper';
import { overrideProps, useStyles } from '../../styles';
import {
  ACTION_OWNER,
  DEFAULT_DATE_FORMAT,
  DUE_DATE,
  DUE_DATE_MIN,
  LANGUAGE_NB,
  LS_LANGUAGE,
  OBJECT_BHM,
  OBJECT_DOC,
  REVISION_VALUES,
  ROLE_DL,
  ROLE_PL,
  STATUS_ANSWERED_AND_CLOSED,
  STATUS_DRAFT,
  STATUS_READY_FOR_ISSUE,
  STATUS_RESPONSE_ISSUED,
  STATUS_REVISED,
  STATUS_SENT,
  VALUES
} from 'common/constants';

const Ta = props => {
  const {
    clearTimer,
    createdBy,
    disableEdit,
    displayObjectView,
    formState,
    handleEditorChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    isForward,
    isReloading,
    objectTypes,
    organizations,
    projectId,
    refName,
    setFormState,
    setHasPopup,
    suggestions
  } = props;
  const classes = useStyles();
  const {
    object,
    project: { projectSelected }
  } = useSelector(state => state);
  const [dateField, setDateField] = useState({ focused: false, open: false });
  const [actionOwners, setActionOwners] = useState([]);
  const { errors, isRevision, mentionedObjs, mentionedUsers } = formState;
  const valsField = isRevision ? REVISION_VALUES : VALUES;
  const vals = formState[valsField];
  const status = vals.status?.name || STATUS_DRAFT;
  const initState = [STATUS_DRAFT, STATUS_READY_FOR_ISSUE].includes(status);
  const senderState = initState || status === STATUS_RESPONSE_ISSUED;
  const showResponseState =
    [
      STATUS_SENT,
      STATUS_RESPONSE_ISSUED,
      STATUS_REVISED,
      STATUS_ANSWERED_AND_CLOSED
    ].includes(status) ||
    !!isRevision ||
    (vals.metadata && vals.metadata.revision > 0);
  const showSenderInfo =
    (isCreatorOrg && !senderState) || (!isCreatorOrg && isRevision);
  const recipientOrg = organizations?.find(
    org => org.id === vals.discipline_type_id
  );
  const disciplines = recipientOrg?.name
    ? object.disciplineTypes[recipientOrg.name]
    : [];
  const selected_discipline =
    vals.disc_type_id && disciplines
      ? disciplines.find(disc => disc.id === vals.disc_type_id)
      : null;

  const handleDateSelection = value => {
    setDateField(df => ({ ...df, focused: value, open: value }));
    setHasPopup(value);
  };

  const handleDateChange = date => {
    const due_date =
      !date || isNaN(date)
        ? date && date.target
          ? date.target.value
          : ''
        : formatDate(date, DEFAULT_DATE_FORMAT);

    setFormState(formState => ({
      ...formState,
      [valsField]: {
        ...formState[valsField],
        metadata: {
          ...formState[valsField].metadata,
          due_date,
          due_date_min: due_date
        }
      }
    }));
  };

  const handleDisciplineTypeChange = (evt, value) => {
    setFormState(formState => {
      return {
        ...formState,
        [valsField]: {
          ...formState[valsField],
          disc_type_id: value?.id || null, // set to null instead of undefined
          metadata: {
            ...formState[valsField].metadata,
            action_owner: null
          }
        },
        prevValues:
          formState.prevValues.metadata &&
          !formState.prevValues.metadata?.[ACTION_OWNER]
            ? {
                ...formState.prevValues,
                disc_type_id: formState.prevValues.disc_type_id || null,
                metadata: {
                  ...formState.prevValues.metadata,
                  action_owner: null
                }
              }
            : { ...formState.prevValues }
      };
    });
  };

  const handleActionOwnerChange = (evt, value) => {
    setFormState(formState => ({
      ...formState,
      [valsField]: {
        ...formState[valsField],
        metadata: {
          ...formState[valsField].metadata,
          action_owner: {
            email: value.email,
            id: value.id
          }
        }
      }
    }));
  };

  useEffect(() => {
    // all org users that belong to the project and selected discipline
    const orgProjUsers =
      vals.disc_type_id && recipientOrg?.users.length > 0
        ? recipientOrg.users.filter(user =>
            user.project_settings.some(
              ps =>
                ps.role.name === ROLE_PL ||
                ps.discipline_type?.id === vals.disc_type_id
            )
          )
        : [];

    let actionOwner;
    if (vals.metadata?.action_owner) {
      actionOwner = vals.metadata.action_owner;
    } else if (vals.disc_type_id) {
      actionOwner = orgProjUsers.find(user =>
        user.project_settings.some(
          ps =>
            ps.role.name === ROLE_DL &&
            ps.discipline_type?.id === vals.disc_type_id
        )
      );
      if (!actionOwner)
        // if dl is not found, set PL as action owner
        actionOwner = orgProjUsers.find(user =>
          user.project_settings.some(ps => ps.role.name === ROLE_PL)
        );
    }

    setActionOwners(orgProjUsers);
    setFormState(formState => {
      const ao_metadata = actionOwner
        ? { action_owner: { email: actionOwner.email, id: actionOwner.id } }
        : {};
      return {
        ...formState,
        [valsField]: {
          ...formState[valsField],
          metadata: {
            ...formState[valsField].metadata,
            ...ao_metadata
          }
        }
      };
    });
    // eslint-disable-next-line
  }, [recipientOrg, vals.disc_type_id]);

  const showReference = useMemo(
    () => !!projectSelected?.settings?.bhm_ta_reference_field,
    [projectSelected]
  );

  let {
    inquiry,
    new_inquiry,
    new_response,
    new_solutions,
    reference,
    response,
    solutions
  } = vals.metadata;

  inquiry = inquiry || [];
  solutions = solutions || [];
  response = response || [];

  return (
    <div>
      <Divider />
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={4}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Created by"
                  id="common.CREATED_BY"
                />
              }
              size="small"
              value={createdBy || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <FormControl
              disabled={!initState || disableEdit}
              fullWidth
              variant="outlined">
              <InputLabel
                className={classNames(
                  classes.dateLabel,
                  hasError(DUE_DATE) ? classes.dateLabelError : ''
                )}
                htmlFor="outlined-adornment-date">
                <FormattedMessage
                  defaultMessage="Answer Deadline"
                  id="object.ANSWER_DEADLINE"
                />
              </InputLabel>
              <OutlinedInput
                className={classes.whiteBackground}
                disabled={!initState || disableEdit}
                endAdornment={
                  <InputAdornment position="end">
                    <>
                      {dateField.focused &&
                      vals.metadata?.due_date &&
                      initState &&
                      !disableEdit ? (
                        <IconButton
                          className={classes.grayIcon}
                          disabled={disableEdit}
                          edge="end"
                          onClick={handleDateChange}
                          size="small">
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {!initState || disableEdit ? null : (
                        <IconButton
                          className={classes.grayIcon}
                          edge="end"
                          onClick={() => handleDateSelection(true)}
                          size="small">
                          <EventIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  </InputAdornment>
                }
                error={hasError(DUE_DATE) || hasError(DUE_DATE_MIN)}
                id="outlined-adornment-date"
                inputProps={{ style: overrideProps.content }}
                labelWidth={
                  localStorage.getItem(LS_LANGUAGE) === LANGUAGE_NB ? 100 : 110
                }
                onBlur={() => setDateField(df => ({ ...df, focused: false }))}
                onChange={handleDateChange}
                onFocus={() => setDateField(df => ({ ...df, focused: true }))}
                onMouseEnter={() =>
                  setDateField(df => ({ ...df, focused: true }))
                }
                onMouseLeave={() =>
                  setDateField(df => ({ ...df, focused: false }))
                }
                value={vals.metadata?.due_date || ''}
              />
              <FormHelperText error id="dueDateHelperText">
                {hasError(DUE_DATE)
                  ? formState.errors.due_date[0]
                  : hasError(DUE_DATE_MIN)
                  ? formState.errors.due_date_min[0]
                  : null}
              </FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                clearable
                disablePast
                onAccept={() => handleDateSelection(false)}
                onChange={handleDateChange}
                onClose={() => handleDateSelection(false)}
                open={dateField.open}
                TextFieldComponent={() => null}
                value={vals.due_date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={3} xs={4}>
            <Autocomplete
              className={classes.whiteBackground}
              disabled={(senderState && !initState) || disableEdit}
              filterSelectedOptions
              getOptionLabel={option => option.name || ''}
              name="discipline_type_id"
              onChange={(event, value) =>
                handleDisciplineTypeChange(event, value)
              }
              options={disciplines}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Select Discipline Type"
                      id="object.SELECT_DISCIPLINE_TYPE"
                    />
                  }
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={selected_discipline || ''}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <Autocomplete
              className={classes.whiteBackground}
              disableClearable
              disabled={senderState || disableEdit}
              filterSelectedOptions
              getOptionLabel={option => option.email || ''}
              onChange={(event, value) => handleActionOwnerChange(event, value)}
              options={actionOwners}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Action Owner"
                      id="object.ACTION_OWNER"
                    />
                  }
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={vals.metadata.action_owner || ''}
            />
          </Grid>
          {showReference ? (
            <Grid item xs={12}>
              <>
                <Typography className={classes.boldLabel}>
                  <FormattedMessage
                    defaultMessage="Reference (drawing number, etc.)"
                    id="object.REFERENCE2"
                  />
                  :
                </Typography>
                <WysWyg
                  clearTimer={clearTimer}
                  defaultValue={formatForEditor(reference) || ''}
                  displayObjectView={displayObjectView}
                  handleMentionItems={handleMentionItems}
                  handleParentEditorChange={handleEditorChange}
                  isReloading={isReloading}
                  key="reference"
                  mentionedObjs={mentionedObjs}
                  mentionedUsers={mentionedUsers}
                  name="reference"
                  noToolbar
                  objectTypes={objectTypes}
                  projectId={projectId}
                  readOnly={!initState || disableEdit}
                  setHasPopup={setHasPopup}
                  suggestions={suggestions.filter(
                    s => s.object_type && s.object_type === OBJECT_DOC
                  )}
                />
              </>
            </Grid>
          ) : null}
          <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
            <Typography className={classes.boldLabel}>
              <FormattedMessage defaultMessage="Query" id="object.QUERY" />: *
            </Typography>
          </Grid>
          {inquiry.map((query, index) =>
            showSenderInfo ||
            (!new_inquiry && !isRevision) ||
            ((isRevision || new_inquiry) && index + 1 < inquiry.length) ? (
              <Grid
                className={
                  index > 0
                    ? classes.revisionEditorEdit
                    : classes.noBottomPadding
                }
                item
                key={`grid_inquiry_${index}`}
                md={12}
                xs={12}>
                <WysWyg
                  clearTimer={clearTimer}
                  defaultValue={query || ''}
                  displayObjectView={displayObjectView}
                  handleMentionItems={handleMentionItems}
                  isReloading={isReloading}
                  key={`inquiry_${index}`}
                  name={`inquiry_${index}`}
                  projectId={projectId}
                  readOnly={!isForward}
                />
              </Grid>
            ) : null
          )}
          {(isRevision || inquiry.length === 0 || new_inquiry) &&
          senderState &&
          !disableEdit ? (
            <Grid
              className={inquiry.length > 1 ? classes.revisionEditorEdit : ''}
              item
              md={12}
              xs={12}>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={
                  new_inquiry
                    ? new_inquiry
                    : inquiry.length > 0
                    ? inquiry[inquiry.length - 1]
                    : ''
                }
                displayObjectView={displayObjectView}
                error={hasError('new_inquiry_text')}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                isReloading={isReloading}
                mentionedObjs={mentionedObjs}
                mentionedUsers={mentionedUsers}
                name="new_inquiry"
                objectTypes={objectTypes}
                projectId={projectId}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
              {hasError('new_inquiry_text') ? (
                <FormHelperText error id="inquiryHelperText">
                  {errors.new_inquiry_text[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          ) : null}
          {refName === OBJECT_BHM ? null : (
            <>
              <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
                <Typography className={classes.boldLabel}>
                  <FormattedMessage
                    defaultMessage="Possible Solutions"
                    id="object.POSSIBLE_SOLUTIONS"
                  />
                  : *
                </Typography>
              </Grid>
              {solutions.map((solution, index) =>
                showSenderInfo ||
                (!new_solutions && !isRevision) ||
                ((isRevision || new_solutions) &&
                  index + 1 < solutions.length) ? (
                  <Grid
                    className={
                      index > 0
                        ? classes.revisionEditorEdit
                        : classes.noBottomPadding
                    }
                    item
                    key={`grid_solutions_${index}`}
                    md={12}
                    xs={12}>
                    <WysWyg
                      clearTimer={clearTimer}
                      defaultValue={solution || ''}
                      displayObjectView={displayObjectView}
                      handleMentionItems={handleMentionItems}
                      isReloading={isReloading}
                      key={`solutions_${index}`}
                      name={`solutions_${index}`}
                      projectId={projectId}
                      readOnly={!isForward}
                    />
                  </Grid>
                ) : null
              )}
              {(isRevision || solutions.length === 0 || new_solutions) &&
              senderState &&
              !disableEdit ? (
                <Grid
                  className={
                    solutions.length > 1 ? classes.revisionEditorEdit : ''
                  }
                  item
                  md={12}
                  xs={12}>
                  <WysWyg
                    clearTimer={clearTimer}
                    defaultValue={
                      new_solutions
                        ? new_solutions
                        : solutions.length > 0
                        ? solutions[solutions.length - 1]
                        : ''
                    }
                    displayObjectView={displayObjectView}
                    error={hasError('new_solutions_text')}
                    handleMentionItems={handleMentionItems}
                    handleParentEditorChange={handleEditorChange}
                    isReloading={isReloading}
                    mentionedObjs={mentionedObjs}
                    mentionedUsers={mentionedUsers}
                    name="new_solutions"
                    objectTypes={objectTypes}
                    projectId={projectId}
                    setHasPopup={setHasPopup}
                    suggestions={suggestions}
                  />
                  {hasError('new_solutions_text') ? (
                    <FormHelperText error id="solutionsHelperText">
                      {errors.new_solutions_text[0]}
                    </FormHelperText>
                  ) : null}
                </Grid>
              ) : null}
            </>
          )}
        </Grid>
      </CardContent>
      <Divider className={classes.dividerMargin} />
      {showResponseState ? (
        <CardContent className={classes.grayContent}>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
              <Typography className={classes.boldLabel}>
                <FormattedMessage
                  defaultMessage="Response"
                  id="object.RESPONSE"
                />
                : *
              </Typography>
            </Grid>
            {response.map((resp, index) =>
              senderState ||
              (!vals.access_policy_change_status && !isRevision) ||
              ((new_response || isRevision) && index + 1 < response.length) ? (
                <Grid
                  className={
                    index > 0
                      ? classes.revisionEditorEdit
                      : classes.noBottomPadding
                  }
                  item
                  key={`grid_response_${index}`}
                  md={12}
                  xs={12}>
                  <WysWyg
                    defaultValue={resp || ''}
                    displayObjectView={displayObjectView}
                    handleMentionItems={handleMentionItems}
                    isReloading={isReloading}
                    key={`response_${index}`}
                    name={`response_${index}`}
                    projectId={projectId}
                    readOnly
                  />
                </Grid>
              ) : null
            )}
            {isRevision ||
            response.length === 0 ||
            new_response ||
            vals.metadata.revision > 0 ? (
              <Grid
                className={
                  response.length > 1 ? classes.revisionEditorEdit : ''
                }
                item
                md={12}
                xs={12}>
                {senderState || disableEdit ? (
                  response.length > 0 ? null : (
                    <WysWyg name="new_response" readOnly />
                  )
                ) : (
                  <>
                    <WysWyg
                      clearTimer={clearTimer}
                      defaultValue={
                        new_response
                          ? new_response
                          : response.length > 0
                          ? response[response.length - 1]
                          : ''
                      }
                      displayObjectView={displayObjectView}
                      error={hasError('new_response_text')}
                      handleMentionItems={handleMentionItems}
                      handleParentEditorChange={handleEditorChange}
                      isReloading={isReloading}
                      mentionedObjs={mentionedObjs}
                      mentionedUsers={mentionedUsers}
                      name="new_response"
                      objectTypes={objectTypes}
                      projectId={projectId}
                      setHasPopup={setHasPopup}
                      suggestions={suggestions}
                    />
                    {hasError('new_response_text') ? (
                      <FormHelperText error id="responseHelperText">
                        {errors.new_response_text[0]}
                      </FormHelperText>
                    ) : null}
                  </>
                )}
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      ) : null}
    </div>
  );
};

Ta.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isForward: PropTypes.bool,
  isReloading: PropTypes.bool,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  refName: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Ta;
